import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Banner from './components/Banner'
import Footer from './components/Footer'
import Faq from './components/Faq'
import Coming from './components/Coming'
import Process from './components/Process'
import Testimonials from './components/Testimonials'
import Aos from 'aos'

export default function App() {
  React.useEffect(() => {
    Aos.init({ duration: 600, once: true })
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <Header />
              <Banner />
              <Process />
              <Testimonials />
              <Coming />
              <Faq />
              <Footer />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}