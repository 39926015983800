import React, { useState, useRef, useEffect, useCallback } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import {
  ArrowRight,
  Zap,
  Globe,
  MessageSquare,
  Users,
  PhoneCall,
  Play,
  ChevronLeft,
  ChevronRight,
  Building2,
  X,
} from 'lucide-react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import ReactDOM from 'react-dom';

const VideoModal = ({ testimonial, onClose }) => {
  const isVimeoVideo = testimonial.videoUrl.includes('vimeo');

  const videoUrl = isVimeoVideo
    ? `${testimonial.videoUrl}&autoplay=1&muted=0`
    : testimonial.videoUrl;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-75 z-[9999] flex items-center justify-center p-4" onClick={onClose}>
      <div className="relative w-full max-w-4xl bg-black rounded-xl overflow-hidden shadow-2xl" onClick={(e) => e.stopPropagation()}>
        <div className="relative pt-[56.25%]">
          {isVimeoVideo ? (
            <iframe
              src={videoUrl}
              className="absolute top-0 left-0 w-full h-full"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              allowFullScreen
              title={testimonial.videoTitle || `${testimonial.name}'s Testimonial`}
            />
          ) : (
            <video
              src={testimonial.videoUrl}
              className="absolute top-0 left-0 w-full h-full object-cover"
              controls
              autoPlay
              muted={false}
            />
          )}
          <button
            className="absolute top-4 right-4 text-white hover:text-gray-200 transition-colors duration-200 bg-black/50 p-2 rounded-full hover:bg-black/70"
            onClick={onClose}
          >
            <X size={24} />
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

const VSLModal = ({ onClose, currentTime }) => {
  const [isModalVideoLoaded, setIsModalVideoLoaded] = useState(false);
  const fullscreenPlayerRef = useRef(null);

  useEffect(() => {
    const initializeFullscreenPlayer = () => {
      if (window.Vimeo) {
        fullscreenPlayerRef.current = new window.Vimeo.Player('vsl-video-fullscreen');
        fullscreenPlayerRef.current
          .ready()
          .then(() => {
            fullscreenPlayerRef.current.setCurrentTime(currentTime);
            fullscreenPlayerRef.current.play();
            setIsModalVideoLoaded(true);
          })
          .catch((error) => {
            console.warn('Vimeo player ready error:', error);
          });
      }
    };

    initializeFullscreenPlayer();

    return () => {
      if (fullscreenPlayerRef.current) {
        fullscreenPlayerRef.current.unload();
      }
    };
  }, [currentTime]);

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-75 z-[9999] flex items-center justify-center p-4" onClick={onClose}>
      <div className="relative w-full max-w-4xl bg-black rounded-xl overflow-hidden shadow-2xl" onClick={(e) => e.stopPropagation()}>
        <div className="relative pt-[56.25%]">
          {!isModalVideoLoaded && (
            <div className="absolute inset-0 bg-black flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
            </div>
          )}
          <iframe
            id="vsl-video-fullscreen"
            src={`https://player.vimeo.com/video/1031160579?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=0&controls=1&dnt=1&playsinline=1&quality=auto&transparent=1&pip=0&download=0&background=0&color=4299E1`}
            className="absolute top-0 left-0 w-full h-full"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Train And Scale LLC"
          />
          <button
            className="absolute top-4 right-4 text-white hover:text-gray-200 transition-colors duration-200 bg-black/50 p-2 rounded-full hover:bg-black/70"
            onClick={onClose}
          >
            <X size={24} />
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

const PhoneDisplay = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [showTestimonialVideo, setShowTestimonialVideo] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const playerRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoVolume, setVideoVolume] = useState(1);
  const [showFullscreenVideo, setShowFullscreenVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPreloading, setIsPreloading] = useState(true);

  const testimonials = [
    {
      id: 1,
      name: 'Colt Mosdef',
      company: 'Reward Over Risk LLC',
      text: 'I used to do $10,000 per month, now I do $10,000 every single week.',
      image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
      videoUrl:
        'https://player.vimeo.com/video/1031079685?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      role: 'Founder & CEO',
      videoTitle: 'Colt Mosdef: Founder of Reward Over Risk LLC',
    },
    {
      id: 2,
      name: 'Jamal Robinson',
      company: 'Fojo Digital LLC',
      text: "When starting with Train & Scale, we were stuck hovering between the $10,000 to $20,000 per month mark, but Train & Scale helped us to actually surpass the $50,000 per month mark, and now we're hitting that consistently.",
      image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
      videoUrl:
        'https://player.vimeo.com/video/1031081061?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      role: 'Co-Founder & CEO',
      videoTitle: 'Jamal Robinson: Founder of Fojo Digital LLC',
    },
    {
      id: 3,
      name: 'Peter Zhang',
      company: 'Steady Growth LLC',
      text: "From September to February we're almost at $100,000 in revenue. I'm very proud of the work.",
      image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
      videoUrl:
        'https://player.vimeo.com/video/1031077338?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      role: 'Founder & CEO',
      videoTitle: 'Peter Zhang: Founder of Steady Growth LLC',
    },
  ];

  useEffect(() => {
    // Ensure the Vimeo Player API script is loaded
    const initializePlayer = () => {
      if (!playerRef.current && window.Vimeo) {
        const player = new window.Vimeo.Player('vsl-video', {
          id: 1031160579,
          background: 1,
          autoplay: false,
          muted: false,
          controls: false,
          loop: false,
          dnt: true,
          playsinline: true,
          quality: 'auto',
          pip: false,
        });

        playerRef.current = player;
        setVideoPlayer(player);

        // Event listeners
        player.on('play', () => setIsVideoPlaying(true));
        player.on('pause', () => setIsVideoPlaying(false));
        player.on('timeupdate', (data) => setVideoTime(data.seconds));

        player.on('loaded', () => {
          setIsLoading(false);
          setIsPreloading(false);
          player.pause();
        });

        // Get duration and volume
        player.getDuration().then(setVideoDuration);
        player.getVolume().then(setVideoVolume);

        // Set current time to 0 and pause
        player.setCurrentTime(0).then(() => player.pause());
      }
    };

    if (window.Vimeo) {
      initializePlayer();
    } else {
      const vimeoScript = document.createElement('script');
      vimeoScript.src = 'https://player.vimeo.com/api/player.js';
      vimeoScript.async = true;
      vimeoScript.onload = initializePlayer;
      document.head.appendChild(vimeoScript);
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.off('play');
        playerRef.current.off('pause');
        playerRef.current.off('timeupdate');
        playerRef.current.off('loaded');
        playerRef.current.unload();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const scrollContainer = document.querySelector('.scrollbar-hide');
    if (scrollContainer) {
      const updateScrollIndicator = () => {
        const scrollPercent = (scrollContainer.scrollTop / (scrollContainer.scrollHeight - scrollContainer.clientHeight)) * 70;
        scrollContainer.parentElement.style.setProperty('--scroll-percent', `${scrollPercent}%`);
      };
      
      scrollContainer.addEventListener('scroll', updateScrollIndicator);
      return () => scrollContainer.removeEventListener('scroll', updateScrollIndicator);
    }
  }, []);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handlePlayPause = useCallback(() => {
    if (playerRef.current) {
      if (isVideoPlaying) {
        playerRef.current.pause();
      } else {
        playerRef.current
          .setMuted(false)
          .then(() => {
            playerRef.current.play();
          })
          .catch((error) => {
            console.warn('Error unmuting video:', error);
            playerRef.current.play();
          });
      }
    }
  }, [isVideoPlaying]);

  const handleSeek = useCallback(
    (e) => {
      if (playerRef.current && videoDuration) {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const percentage = x / rect.width;
        playerRef.current.setCurrentTime(percentage * videoDuration);
      }
    },
    [videoDuration]
  );

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    if (playerRef.current) {
      playerRef.current.setVolume(newVolume);
      setVideoVolume(newVolume);
    }
  };

  const handleFullscreen = () => {
    if (playerRef.current) {
      playerRef.current
        .pause()
        .then(() => {
          const currentTime = videoTime;
          setShowFullscreenVideo(true);
        })
        .catch((error) => {
          console.warn('Error pausing video:', error);
          setShowFullscreenVideo(true);
        });
    }
  };

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    if (!document.getElementById('modal-root')) {
      const modalRoot = document.createElement('div');
      modalRoot.id = 'modal-root';
      document.body.appendChild(modalRoot);
    }
  }, []);

  const videoSrc =
    'https://player.vimeo.com/video/1031160579?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=0&muted=0&controls=0&dnt=1&playsinline=1&quality=auto&transparent=1&pip=0&download=0&background=1';

  const memoizedTestimonials = testimonials;

  return (
    <div className="size-full absolute z-10 overflow-hidden flex flex-col items-center justify-start rounded-2xl md:rounded-[30px]">
      <div className="w-full relative bg-black flex flex-col" style={{ aspectRatio: 1 / 1 }}>
        <div className="flex-grow relative">
          {isPreloading && (
            <div className="absolute inset-0 bg-black flex items-center justify-center z-10">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
            </div>
          )}
          <div className="relative w-full h-full">
            <iframe
              id="vsl-video"
              src={videoSrc}
              className="absolute top-0 left-0 w-full h-full"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; fullscreen; picture-in-picture"
              title="Train And Scale LLC"
              loading="eager"
              fetchpriority="high"
            />
          </div>
          {isLoading && (
            <div className="absolute inset-0 bg-black flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
            </div>
          )}
        </div>

        <div className="h-auto bg-black flex flex-col justify-center px-2 group">
          <div
            className="w-full h-[1px] bg-gray-700 rounded-full mb-0 cursor-pointer hover:h-1.5 transition-all"
            onClick={handleSeek}
          >
            <div
              className="h-full bg-primary rounded-full"
              style={{ width: `${(videoTime / videoDuration) * 100}%` }}
            />
          </div>

          <div className="flex items-center justify-between h-9">
            <div className="flex items-center space-x-2">
              <button className="text-white hover:text-primary transition-colors border-0 p-0" onClick={handlePlayPause}>
                {isVideoPlaying ? (
                  <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                    <rect x="6" y="4" width="4" height="16" />
                    <rect x="14" y="4" width="4" height="16" />
                  </svg>
                ) : (
                  <Play className="w-4 h-4" />
                )}
              </button>
              <span className="text-white text-[11px] font-medium">
                {formatTime(videoTime)} / {formatTime(videoDuration)}
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <div className="group/volume flex items-center">
                <button className="text-white hover:text-primary transition-colors border-0 p-0">
                  <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15.536 8.464a5 5 0 010 7.072M18.364 5.636a9 9 0 010 12.728M5 8.5h2l4-4v15l-4-4H5a1 1 0 01-1-1v-5a1 1 0 011-1z"
                    />
                  </svg>
                </button>
                <div className="w-0 group-hover/volume:w-20 overflow-hidden transition-all duration-300">
                  <input
                    type="range"
                    className="w-16 mx-2"
                    min="0"
                    max="1"
                    step="0.1"
                    value={videoVolume}
                    onChange={handleVolumeChange}
                  />
                </div>
              </div>

              <button className="text-white hover:text-primary transition-colors border-0 p-0" onClick={handleFullscreen}>
                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 8V6a2 2 0 012-2h2M4 16v2a2 2 0 002 2h2m8-16h2a2 2 0 012 2v2m-2 12h2a2 2 0 002-2v-2"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="size-full bg-[#F6FAFF] p-3">
        <h3 className="text-[17px] font-semibold text-[#2F353F] mb-3">Client Success Stories</h3>

        <div className="relative h-[calc(100%-24px)] overflow-hidden rounded-xl">
          <motion.div
            className="absolute inset-0 flex items-center"
            animate={{ x: `-${currentTestimonial * 100}%` }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            {memoizedTestimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                className="w-full h-full flex-shrink-0 p-3 relative bg-[#EEF6FF]"
              >
                <div className={`flex items-start space-x-3 ${testimonial.id === 2 ? 'h-full' : ''}`}>
                  <div
                    className="relative size-12 flex-shrink-0 cursor-pointer group"
                    onClick={() => {
                      setSelectedTestimonial(testimonial);
                      setShowTestimonialVideo(true);
                    }}
                  >
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-30 group-hover:bg-opacity-40 transition-all duration-200 rounded-lg flex items-center justify-center">
                      <Play className="w-4 h-4 text-white opacity-80 group-hover:opacity-100 group-hover:scale-110 transition-all duration-200" />
                    </div>
                  </div>

                  <div className={`flex-1 ${testimonial.id === 2 ? 'h-full flex flex-col' : ''}`}>
                    {testimonial.id === 2 ? (
                      <div className="flex-1 relative custom-scrollbar" style={{ maxHeight: '50%' }}>
                        <div 
                          className="absolute inset-0 overflow-y-auto scrollbar-hide"
                        >
                          <p className="text-[13px] text-gray-700 italic !leading-normal pt-0.5 pr-4 whitespace-normal">
                            "{testimonial.text}"
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="flex-1">
                        <p className="text-[13px] text-gray-700 italic !leading-normal pt-0.5">
                          "{testimonial.text}"
                        </p>
                      </div>
                    )}
                    <div className={`text-[12px] !leading-normal ${testimonial.id === 2 ? 'mt-1' : 'mt-2'} pt-1.5 border-t border-gray-100/50`}>
                      <p className="font-semibold text-gray-900">{testimonial.name}</p>
                      <p className="text-blue-600">{testimonial.role}</p>
                      <p className="text-gray-500">{testimonial.company}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>

          <div className="absolute bottom-3 left-0 right-0 flex justify-center space-x-2">
            {memoizedTestimonials.map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 p-0 border-0 rounded-full transition-all duration-200 ${currentTestimonial === index ? 'bg-primary w-4' : 'bg-[#93C5FD] hover:bg-blue-400'
                  }`}
                onClick={() => setCurrentTestimonial(index)}
              />
            ))}
          </div>

          <button
            onClick={prevTestimonial}
            className="absolute flex items-center justify-center border-0 left-3 top-1/2 -translate-y-1/2 p-0 size-5 rounded-full bg-white/80 hover:bg-white text-primary shadow-lg transition-all duration-200 hover:scale-110"
          >
            <ChevronLeft size={14} />
          </button>
          <button
            onClick={nextTestimonial}
            className="absolute flex items-center justify-center border-0 right-3 top-1/2 -translate-y-1/2 p-0 size-5 rounded-full bg-white/80 hover:bg-white text-primary shadow-lg transition-all duration-200 hover:scale-110"
          >
            <ChevronRight size={14} />
          </button>
        </div>
      </div>

      {showTestimonialVideo && selectedTestimonial && (
        <VideoModal
          testimonial={selectedTestimonial}
          onClose={() => setShowTestimonialVideo(false)}
        />
      )}

      <AnimatePresence>
        {showFullscreenVideo && (
          <VSLModal
            onClose={() => {
              setShowFullscreenVideo(false);
              if (videoPlayer) {
                videoPlayer.play();
              }
            }}
            currentTime={videoTime}
          />
        )}
      </AnimatePresence>

      <style jsx global>{`
        .scrollbar-hide {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }
        
        .scrollbar-hide::-webkit-scrollbar {
          display: none;  /* Chrome, Safari and Opera */
        }
        
        .custom-scrollbar {
          position: relative;
        }
        
        .custom-scrollbar::after {
          content: '';
          position: absolute;
          top: 5%;
          right: 0;
          width: 3px;
          height: 90%;
          background: rgba(59, 130, 246, 0.1);
          border-radius: 3px;
        }
        
        .custom-scrollbar::before {
          content: '';
          position: absolute;
          top: 5%;
          right: 0;
          width: 3px;
          height: 30%;
          background: rgb(59, 130, 246);
          border-radius: 3px;
          opacity: 0.8;
          transition: all 0.3s ease;
          z-index: 1;
          transform: translateY(var(--scroll-percent, 0%));
        }
        
        .custom-scrollbar:hover::before {
          opacity: 1;
          width: 4px;
        }
      `}</style>
    </div>
  );
};

const Hero = ({ isDarkMode }) => {
  const phoneRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  const services = [
    { icon: Building2, text: 'Strategic Funding Options | Up to $5M Available' },
    { icon: Zap, text: 'An Irresistible Offer (If Needed)' },
    { icon: Globe, text: 'High Converting Website Built With Fortune 500 Technologies' },
    { icon: MessageSquare, text: 'Sales Automations and Frameworks' },
    { icon: Users, text: 'Sales Management' },
  ];

  const additionalServices = [
    { icon: PhoneCall, title: 'Dedicated Sales Manager', description: 'Weekly 1-on-1 Calls' },
    {
      icon: MessageSquare,
      title: '3,000+ Strategic Messages',
      description: (
        <div className="flex flex-col">
          <div className="flex items-center space-x-4 mt-1">
            {[FaFacebook, FaInstagram, FaLinkedin].map((Icon, index) => (
              <div
                key={index}
              >
                <Icon
                  size={16}
                  className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'
                    } hover:text-primary transition-colors duration-200 cursor-pointer`}
                />
              </div>
            ))}
          </div>
        </div>
      ),
    },
    { icon: Globe, title: 'Premium Website', description: 'Next.js Technologies' },
  ];

  const gradientAnimation = {
    initial: {
      backgroundPosition: '0% 50%',
      backgroundSize: '200% 200%',
    },
    animate: {
      backgroundPosition: ['0% 50%', '100% 50%', '50% 100%', '0% 50%'],
      transition: {
        duration: 20,
        ease: 'linear',
        repeat: Infinity,
      },
    },
  };

  const scrollToBooking = () => {
    const bookingSection = document.getElementById('booking-widget');
    if (bookingSection) {
      bookingSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const scrollToHowItWorks = () => {
    const servicesSection = document.getElementById('services-overview');
    if (servicesSection) {
      servicesSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className='size-full' style={{ perspective: '1000px', transform: 'translateZ(0)' }}>
      <div
        ref={phoneRef}
        className="w-full h-full"
        style={{
          // aspectRatio: '1 / 2',
        }}
      >
        <PhoneDisplay />
      </div>
    </div>
  );
};

export default Hero;