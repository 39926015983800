import React from 'react'

export default function Card1() {
  const CardList = [
    {
      title: "Get Started with Train & Scale",
      des: "Our team will start building the overall system that creates authority, scalability and clarity for your business."
    },
    {
      title: "Connect with our Funding Partners",
      des: "We'll introduce you to our trusted funding partners who specialize in business growth capital and understand your unique needs. Our partners are committed to your success."
    },
    {
      title: "Access Your Funding",
      des: "Get approved for $30,000 - $5,000,000 in funding at 0% interest for 12 months. Start scaling your business immediately with the capital you need to achieve your goals."
    },
  ]
  return (
    <div className='Card1'>
      <div className="Card-Heading text-center mb-6 lg:mb-9">
        <h4 className='text-2xl lg:text-[28px] xl:text-[48px] font-semibold tracking-[0.54px] text-[#111827] !leading-[1.27] mb-3 lg:mb-4' data-aos="fade-up" data-aos-delay="300">
          Our Funding Process
        </h4>
      </div>
      <div className="Card-section">
        {CardList.map((item, idx) => (
          <div key={idx} data-aos="fade-up"
            data-aos-delay={`${idx + 2}00`} data-aos-offset="0" className='Card-list hover:border-[#2A6BEE] hover:bg-[linear-gradient(92deg,_#FFF_0.06%,_#E0EDFE_100.07%)]  mb-4 lg:mb-5 last:mb-0 cursor-pointer group flex md:items-center gap-5 lg:gap-6 border lg:border-[1.5px] border-[#E0EDFE] rounded-[10px] lg:rounded-xl xl:rounded-[20px] p-4 md:p-5 lg:p-6'>
            <div className=' w-10 lg:w-[52px] xl:w-[64px] h-10 lg:h-[52px] xl:h-16 text-base lg:text-lg xl:text-xl !leading-[1.5] flex-[0_0_auto] group-hover:border-[4px] bg-[#F2F5FF] !text-[#2A6BEE] group-hover:!text-white group-hover:bg-[#2A6BEE] rounded-full flex items-center justify-center'>{idx + 1}</div>
            <div className='lg:w-[78%]'>
              <h6 className='text-[#111827] text-sm md:text-base lg:text-lg xl:text-[29px] font-semibold tracking-[0.055px] !leading-[1.4] mb-2 '>{item.title}</h6>
              <p className='text-[#4B5563] text-xs lg:text-base !leading-[1.58] tracking-[0.014px;]'>{item.des}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
