import React from 'react'
import logo from '../assets/img/logo.png'
import { Link } from 'react-scroll'

export default function Logo() {
  return (
    <Link className="logo block cursor-pointer" to="banner" spy={true} smooth={true} duration={600} offset={0}>
      <img src={logo} className='h-8 hidden lg:inline-block' alt="site-logo" />
      <div className="lg:hidden bg-[#1F2937] py-2 px-3 rounded-full">
        <img className='h-6' src={logo} alt="" />
      </div>
    </Link>
  )
}
