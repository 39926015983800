import React, { useRef, useEffect, useState } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { Users, Database, BookOpen, Lightbulb, ArrowRight, Brain, Zap, Eye, PieChart, Mail, X } from 'lucide-react';
import { FaGoogle } from 'react-icons/fa';
import ReactFlow, { Background } from 'react-flow-renderer';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Left section style options
const LeftSectionStyle4 = ({ title, subtitle, keyFeatures, features, fontSizes, isDarkMode, boxStyles }) => (
  <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-${boxStyles.borderRadius} shadow-${boxStyles.shadowIntensity} overflow-hidden`}>
    <div className={`bg-blue-600 p-${boxStyles.padding}`}>
      <h1
        className="font-bold mb-4 leading-tight text-white"
        style={{ fontSize: `${fontSizes.header}rem` }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className="leading-relaxed text-blue-100"
        style={{ fontSize: `${fontSizes.subheader}rem` }}
      >
        {subtitle}
      </p>
    </div>
    <div className={`p-${boxStyles.padding}`}>
      <h2
        className={`font-semibold mb-8 ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`}
        style={{ fontSize: `${fontSizes.keyFeatures}rem` }}
      >
        {keyFeatures}
      </h2>
      <ul className="space-y-8 mb-10">
        {Array.isArray(features)
          ? features.map((feature, index) => (
            <li key={index} className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {feature}
            </li>
          ))
          : features
        }
      </ul>
    </div>
  </div>
);

const AICapabilityBox = ({ icon: Icon, title, description, link, learnMoreStyle, iconSize }) => (
  <motion.div
    className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
    whileHover={{ scale: 1.02, y: -5 }}
  >
    <div className="flex items-center mb-4">
      <Icon className="text-blue-600 mr-3" aria-hidden="true" style={{ width: iconSize, height: iconSize }} />
      <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
    </div>
    <p className="text-gray-600 mb-4 text-base leading-relaxed">{description}</p>
    <a href={link} className="text-blue-600 hover:text-blue-700 flex items-center text-sm font-medium transition-colors duration-200" style={learnMoreStyle}>
      Learn more <ArrowRight className="ml-1 w-4 h-4" aria-hidden="true" />
    </a>
  </motion.div>
);

const WaitlistModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState('email');

  // Demo Google Sign In handler
  const handleDemoGoogleSignIn = async () => {
    setIsSubmitting(true);
    // Simulate API call with demo data
    await new Promise(resolve => setTimeout(resolve, 1000));
    setEmail('demo@gmail.com');
    setStep('success');
    setIsSubmitting(false);
  };

  // Demo form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setStep('success');
    setIsSubmitting(false);
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        onClick={e => e.stopPropagation()}
        className="bg-white rounded-2xl shadow-2xl w-full max-w-md overflow-hidden"
      >
        {step === 'email' ? (
          <>
            <div className="p-6 pb-0">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold text-gray-900">Join the Waitlist</h3>
                <button
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-500 transition-colors"
                >
                  <X size={24} />
                </button>
              </div>
              <p className="text-gray-600 mb-6">
                Be the first to know when we launch our app.
              </p>
            </div>

            <div className="px-6">
              <div className="flex flex-col gap-4">
                <button
                  onClick={handleDemoGoogleSignIn}
                  className="flex items-center justify-center gap-3 w-full px-4 py-3 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <FaGoogle className="w-5 h-5" />
                  <span className="font-medium">Continue with Google</span>
                </button>

                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or</span>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="p-6">
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email address
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="pl-10 w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="you@example.com"
                    required
                  />
                </div>
              </div>

              <motion.button
                type="submit"
                className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Joining...
                  </span>
                ) : (
                  'Join Waitlist'
                )}
              </motion.button>
            </form>
          </>
        ) : (
          <div className="p-6 text-center">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <motion.svg
                className="w-8 h-8 text-green-500"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </motion.svg>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">You're on the list!</h3>
            <p className="text-gray-600 mb-6">
              We'll notify you when we launch. Thank you for your interest!
            </p>
            <motion.button
              onClick={onClose}
              className="bg-blue-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-700"
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
            >
              Got it
            </motion.button>
          </div>
        )}
      </motion.div>
    </motion.div>,
    document.body
  );
};

const AIAppShowcase = ({ isDarkMode }) => {
  const [fontSizes] = useState({
    header: 2.99,
    subheader: 1.25,
    keyFeatures: 1.875,
    featureTitle: 1.3,
    featureDescription: 1.06,
    aiCapabilitiesTitle: 1.87,
    aiCapabilitiesBoxTitles: 1.3,
    aiCapabilitiesDescription: 1.06,
    aiCapabilitiesLearnMore: 0.96,
    integratingAITitle: 2.03,
    integratingAIDescription: 1.16,
    keyFeaturesIcons: 30,
    aiCapabilitiesIcons: 30,
  });

  const headerRef = useRef(null);
  const headerControls = useAnimation();
  const isHeaderInView = useInView(headerRef, { once: true, amount: 0.3 });
  const controls = useAnimation();
  const isInView = useInView(headerRef, { once: true, amount: 0.3 });

  useEffect(() => {
    if (isInView) {
      controls.start({
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
          type: "spring",
          stiffness: 100,
          damping: 20,
          duration: 0.8
        }
      });
    }
  }, [isInView, controls]);

  useEffect(() => {
    if (isHeaderInView) {
      headerControls.start({
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
          type: "spring",
          stiffness: 100,
          damping: 20,
          duration: 0.8
        }
      });
    }
  }, [isHeaderInView, headerControls]);

  const aiCapabilities = [
    {
      icon: Brain,
      title: "Data Processing and Analysis",
      description: "AI systems process and analyze thousands of documents, images, and data points in seconds, extracting valuable insights.",
      link: "https://example.com/ai-data-analysis"
    },
    {
      icon: Zap,
      title: "Content Generation",
      description: "AI generates human-like text, creates and edits images, composes music, and even produces code.",
      link: "https://example.com/ai-content-generation"
    },
    {
      icon: Eye,
      title: "Perception and Recognition",
      description: "AI models see, hear, and understand their environment, enabling natural language processing and browser control.",
      link: "https://example.com/ai-perception"
    },
    {
      icon: PieChart,
      title: "Decision Making and Prediction",
      description: "AI algorithms make complex decisions and accurate predictions in fields such as finance, healthcare, and logistics.",
      link: "https://example.com/ai-decision-making"
    }
  ];

  const flowNodes = [
    { id: '1', type: 'input', data: { label: 'Community' }, position: { x: 250, y: 0 }, style: { background: '#3B82F6', color: '#fff', borderRadius: '8px', padding: '10px', fontWeight: 'bold' } },
    { id: '2', data: { label: 'CRM' }, position: { x: 100, y: 100 }, style: { background: '#3B82F6', color: '#fff', borderRadius: '8px', padding: '10px', fontWeight: 'bold' } },
    { id: '3', data: { label: 'CSM' }, position: { x: 400, y: 100 }, style: { background: '#3B82F6', color: '#fff', borderRadius: '8px', padding: '10px', fontWeight: 'bold' } },
    { id: '4', type: 'output', data: { label: 'Integrated AI System' }, position: { x: 250, y: 200 }, style: { background: '#3B82F6', color: '#fff', borderRadius: '8px', padding: '10px', fontWeight: 'bold' } },
  ];

  const flowEdges = [
    { id: 'e1-2', source: '1', target: '2', type: 'smoothstep', animated: true, style: { stroke: '#3B82F6', strokeWidth: 2 } },
    { id: 'e1-3', source: '1', target: '3', type: 'smoothstep', animated: true, style: { stroke: '#3B82F6', strokeWidth: 2 } },
    { id: 'e2-4', source: '2', target: '4', type: 'smoothstep', animated: true, style: { stroke: '#3B82F6', strokeWidth: 2 } },
    { id: 'e3-4', source: '3', target: '4', type: 'smoothstep', animated: true, style: { stroke: '#3B82F6', strokeWidth: 2 } },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);

  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <div className='mt-6 rounded-[20px] overflow-hidden'>
        <div style={{ height: 350 }} className="bg-white">
          <ReactFlow
            nodes={flowNodes}
            edges={flowEdges}
            fitView
          >
            <Background color="#f0f0f0" gap={16} />
          </ReactFlow>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default AIAppShowcase;
