import React from 'react'
import Button from '../Button'

export default function Card11() {
    const excellence = [
        {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M7 15H9C9 16.08 10.37 17 12 17C13.63 17 15 16.08 15 15C15 13.9 13.96 13.5 11.76 12.97C9.64 12.44 7 11.78 7 9C7 7.21 8.47 5.69 10.5 5.18V3H13.5V5.18C15.53 5.69 17 7.21 17 9H15C15 7.92 13.63 7 12 7C10.37 7 9 7.92 9 9C9 10.1 10.04 10.5 12.24 11.03C14.36 11.56 17 12.22 17 15C17 16.79 15.53 18.31 13.5 18.82V21H10.5V18.82C8.47 18.31 7 16.79 7 15Z" fill="#2563EB"/>
</svg>`,
            title: 'Time-Sensitive Opportunities',
            p: "Every sales call represents a potential high-ticket client worth an average of $10,000 to $50,000. Our sales reps are ready to help you capture that value and maximize each conversation.",
        },
        {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 7.50001C12.75 4.53938 15.5827 3.02766 21.75 3.00001C21.8486 2.99963 21.9463 3.01878 22.0375 3.05634C22.1286 3.0939 22.2114 3.14913 22.2812 3.21885C22.3509 3.28856 22.4061 3.37139 22.4437 3.46255C22.4812 3.55372 22.5004 3.65141 22.5 3.75001V17.25C22.5 17.4489 22.421 17.6397 22.2803 17.7803C22.1397 17.921 21.9489 18 21.75 18C15.75 18 13.432 19.2098 12 21M12 7.50001C11.25 4.53938 8.41735 3.02766 2.25001 3.00001C2.15141 2.99963 2.05372 3.01878 1.96255 3.05634C1.87139 3.0939 1.78856 3.14913 1.71885 3.21885C1.64913 3.28856 1.5939 3.37139 1.55634 3.46255C1.51878 3.55372 1.49963 3.65141 1.50001 3.75001V17.1595C1.50001 17.6227 1.78688 18 2.25001 18C8.25001 18 10.5764 19.2188 12 21M12 7.50001V21" stroke="#2563EB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
            title: 'Train',
            p: "Our sales reps take the time to learn your offer from A-Z, ensuring they communicate the value of your offer effectively throughout each sales call.",
        },
        {
            icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1L21 5V11ZM12 21C15.75 20 19 15.54 19 11.22V6.3L12 3.18L5 6.3V11.22C5 15.54 8.25 20 12 21Z" fill="#2563EB"/>
</svg>

`,
            title: 'Quality Assured',
            p: 'Our sales reps are verified before being placed with any of our clients.',
        },
        {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 3.75C9.81196 3.75 7.71354 4.61919 6.16637 6.16637C4.61919 7.71354 3.75 9.81196 3.75 12C3.75 14.188 4.61919 16.2865 6.16637 17.8336C7.71354 19.3808 9.81196 20.25 12 20.25C14.188 20.25 16.2865 19.3808 17.8336 17.8336C19.3808 16.2865 20.25 14.188 20.25 12C20.25 9.81196 19.3808 7.71354 17.8336 6.16637C16.2865 4.61919 14.188 3.75 12 3.75ZM2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM12 7.5C10.8065 7.5 9.66193 7.97411 8.81802 8.81802C7.97411 9.66193 7.5 10.8065 7.5 12C7.5 13.1935 7.97411 14.3381 8.81802 15.182C9.66193 16.0259 10.8065 16.5 12 16.5C13.1935 16.5 14.3381 16.0259 15.182 15.182C16.0259 14.3381 16.5 13.1935 16.5 12C16.5 10.8065 16.0259 9.66193 15.182 8.81802C14.3381 7.97411 13.1935 7.5 12 7.5ZM6 12C6 10.4087 6.63214 8.88258 7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6C13.5913 6 15.1174 6.63214 16.2426 7.75736C17.3679 8.88258 18 10.4087 18 12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18C10.4087 18 8.88258 17.3679 7.75736 16.2426C6.63214 15.1174 6 13.5913 6 12ZM9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12Z" fill="#2563EB"/>
</svg>`,
            title: 'Scale',
            p: 'Our system handles increasing lead volume efficiently, allowing you to grow without worrying about managing the workload.',
        },
    ]
    const excellence2 = [
        {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 3.75C9.81196 3.75 7.71354 4.61919 6.16637 6.16637C4.61919 7.71354 3.75 9.81196 3.75 12C3.75 14.188 4.61919 16.2865 6.16637 17.8336C7.71354 19.3808 9.81196 20.25 12 20.25C14.188 20.25 16.2865 19.3808 17.8336 17.8336C19.3808 16.2865 20.25 14.188 20.25 12C20.25 9.81196 19.3808 7.71354 17.8336 6.16637C16.2865 4.61919 14.188 3.75 12 3.75ZM2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM12 7.5C10.8065 7.5 9.66193 7.97411 8.81802 8.81802C7.97411 9.66193 7.5 10.8065 7.5 12C7.5 13.1935 7.97411 14.3381 8.81802 15.182C9.66193 16.0259 10.8065 16.5 12 16.5C13.1935 16.5 14.3381 16.0259 15.182 15.182C16.0259 14.3381 16.5 13.1935 16.5 12C16.5 10.8065 16.0259 9.66193 15.182 8.81802C14.3381 7.97411 13.1935 7.5 12 7.5ZM6 12C6 10.4087 6.63214 8.88258 7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6C13.5913 6 15.1174 6.63214 16.2426 7.75736C17.3679 8.88258 18 10.4087 18 12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18C10.4087 18 8.88258 17.3679 7.75736 16.2426C6.63214 15.1174 6 13.5913 6 12ZM9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12Z" fill="#2563EB"/>
</svg>`,
            title: 'Specialized in high-ticket sales',
            p: "Experienced in handling premium opportunities",
        },
        {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 7.50001C12.75 4.53938 15.5827 3.02766 21.75 3.00001C21.8486 2.99963 21.9463 3.01878 22.0375 3.05634C22.1286 3.0939 22.2114 3.14913 22.2812 3.21885C22.3509 3.28856 22.4061 3.37139 22.4437 3.46255C22.4812 3.55372 22.5004 3.65141 22.5 3.75001V17.25C22.5 17.4489 22.421 17.6397 22.2803 17.7803C22.1397 17.921 21.9489 18 21.75 18C15.75 18 13.432 19.2098 12 21M12 7.50001C11.25 4.53938 8.41735 3.02766 2.25001 3.00001C2.15141 2.99963 2.05372 3.01878 1.96255 3.05634C1.87139 3.0939 1.78856 3.14913 1.71885 3.21885C1.64913 3.28856 1.5939 3.37139 1.55634 3.46255C1.51878 3.55372 1.49963 3.65141 1.50001 3.75001V17.1595C1.50001 17.6227 1.78688 18 2.25001 18C8.25001 18 10.5764 19.2188 12 21M12 7.50001V21" stroke="#2563EB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
            title: 'Complete sales toolkit',
            p: "We create and provide premium quality tools and systems",
        },
        {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M21 11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1L21 5V11ZM12 21C15.75 20 19 15.54 19 11.22V6.3L12 3.18L5 6.3V11.22C5 15.54 8.25 20 12 21Z" fill="#2563EB"/>
</svg>`,
            title: 'Proven process',
            p: "Systematic approach to converting leads",
        },
    ]
    const card = [
        {
            title: 'Best Use of Time',
            des: 'Maximize your business potential - time is your most valuable asset',
            per: 75,
            color: '#fff',
            icon: (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M12.6899 3.07422C7.72119 3.07422 3.68994 7.10547 3.68994 12.0742C3.68994 17.043 7.72119 21.0742 12.6899 21.0742C17.6587 21.0742 21.6899 17.043 21.6899 12.0742C21.6899 7.10547 17.6587 3.07422 12.6899 3.07422Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M12.6899 6.07422V12.8242H17.1899" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>)
        },
        {
            title: 'Best Use of Resources',
            des: 'Maximize ROI with experienced sales reps',
            per: 90,
            color: '#F4F8FD',
            icon: (<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6797 4.32617C10.4917 4.32617 8.39323 5.19537 6.84606 6.74254C5.29888 8.28972 4.42969 10.3881 4.42969 12.5762C4.42969 14.7642 5.29888 16.8626 6.84606 18.4098C8.39323 19.957 10.4917 20.8262 12.6797 20.8262C14.8677 20.8262 16.9661 19.957 18.5133 18.4098C20.0605 16.8626 20.9297 14.7642 20.9297 12.5762C20.9297 10.3881 20.0605 8.28972 18.5133 6.74254C16.9661 5.19537 14.8677 4.32617 12.6797 4.32617ZM2.92969 12.5762C2.92969 7.19117 7.29469 2.82617 12.6797 2.82617C18.0647 2.82617 22.4297 7.19117 22.4297 12.5762C22.4297 17.9612 18.0647 22.3262 12.6797 22.3262C7.29469 22.3262 2.92969 17.9612 2.92969 12.5762ZM12.6797 8.07617C11.4862 8.07617 10.3416 8.55028 9.49771 9.39419C8.65379 10.2381 8.17969 11.3827 8.17969 12.5762C8.17969 13.7696 8.65379 14.9142 9.49771 15.7582C10.3416 16.6021 11.4862 17.0762 12.6797 17.0762C13.8732 17.0762 15.0178 16.6021 15.8617 15.7582C16.7056 14.9142 17.1797 13.7696 17.1797 12.5762C17.1797 11.3827 16.7056 10.2381 15.8617 9.39419C15.0178 8.55028 13.8732 8.07617 12.6797 8.07617ZM6.67969 12.5762C6.67969 10.9849 7.31183 9.45875 8.43705 8.33353C9.56227 7.20831 11.0884 6.57617 12.6797 6.57617C14.271 6.57617 15.7971 7.20831 16.9223 8.33353C18.0475 9.45875 18.6797 10.9849 18.6797 12.5762C18.6797 14.1675 18.0475 15.6936 16.9223 16.8188C15.7971 17.944 14.271 18.5762 12.6797 18.5762C11.0884 18.5762 9.56227 17.944 8.43705 16.8188C7.31183 15.6936 6.67969 14.1675 6.67969 12.5762ZM10.4297 12.5762C10.4297 11.9794 10.6667 11.4071 11.0887 10.9852C11.5107 10.5632 12.083 10.3262 12.6797 10.3262C13.2764 10.3262 13.8487 10.5632 14.2707 10.9852C14.6926 11.4071 14.9297 11.9794 14.9297 12.5762C14.9297 13.1729 14.6926 13.7452 14.2707 14.1672C13.8487 14.5891 13.2764 14.8262 12.6797 14.8262C12.083 14.8262 11.5107 14.5891 11.0887 14.1672C10.6667 13.7452 10.4297 13.1729 10.4297 12.5762Z" fill="white"/>
                </svg>
                )
        },
    ]




    return (
        <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 xl:gap-[30px]">
                <div className="left p-4 rounded-2xl md:rounded-[24px] lg:p-6 bg-[#FFFFFF]">
                    <h3 className='text-xl lg:text-2xl xl:text-[31px] !leading-[150%] font-semibold text-[#1F2937] mb-3'>Expert Sales Reps to Convert Quality Leads</h3>
                    <p className='text-sm md:text-base lg:text-lg !leading-[1.5] mb-4 xl:mb-5 bg-[#EEF2FF] p-3 px-5 rounded-xl'>Qualified high-ticket leads cost valuable resources and time to generate. Each unconverted lead means thousands in lost revenue. You need experienced closers to turn these premium opportunities into results.</p>
                    <div className="card flex flex-col gap-3 lg:gap-4 ">
                        {excellence.map((item, index) => (
                            <div className="single linear6 flex gap-2" key={index}>
                                <div className="inner p-4 flex gap-3">
                                    <div className="flex-[0_0_auto] flex items-center justify-center size-12 xl:size-[62px] rounded-xl bg-[#E0EDFE]">
                                        <div dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                    </div>
                                    <div>
                                        <h4 className='text-base lg:text-lg xl:text-xl font-medium mb-1 text-[#111827] !leading-[150%]'>{item.title} </h4>
                                        <p className='text-sm'>{item.p} </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-center rounded-xl xl:rounded-[24px] bg-[#3275F1] p-5 mt-5 lg:mt-6 xl:mt-8">
                        <p className='text-base lg:text-lg xl:text-xl !leading-[1.5] font-medium text-white mb-4 xl:mb-5' >Ready to Convert Your Leads?</p>
                        <Button text="Get Started With Our Sales Team" className="w-full !py-[12px] md:!py-[15px] !justify-center text-center bg-white !text-[19px]" iconClass="!size-8" />
                    </div>
                </div>
                <div className="right ">
                    <div className="card flex flex-col gap-3 lg:gap-4 p-4 rounded-2xl md:rounded-[24px] lg:p-5 bg-[#FFFFFF]">
                        <h3 className='text-xl lg:text-2xl xl:text-[31px] !leading-[150%] font-semibold text-[#1F2937] mb-4 xl:mb-5 max-w-[338px]'>Why Our Sales Reps?</h3>
                        {excellence2.map((item, index) => (
                            <div className="single linear7 flex gap-2" key={index}>
                                <div className="inner p-4 flex gap-3">
                                    <div className="flex-[0_0_auto] flex items-center justify-center size-12 xl:size-[62px] rounded-full bg-[#E0EDFE]">
                                        <div dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                    </div>
                                    <div>
                                        <h4 className='text-base lg:text-lg xl:text-xl font-medium mb-1 text-[#111827] !leading-[150%]'>{item.title} </h4>
                                        <p className='text-sm'>{item.p} </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-center rounded-xl xl:rounded-[24px]  mt-4 xl:mt-5" style={{ backgroundImage: `linear-gradient(289deg, #3C5692 0%, #121A2C 100%)` }}>
                        <div className="p-5 xl:p-6">
                            <div className="flex items-center justify-between mb-3">
                                <div className="flex items-center gap-2">
                                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.7603 4.34766C10.1353 4.34766 4.76025 9.72266 4.76025 16.3477C4.76025 22.9727 10.1353 28.3477 16.7603 28.3477C23.3853 28.3477 28.7603 22.9727 28.7603 16.3477C28.7603 9.72266 23.3853 4.34766 16.7603 4.34766Z" stroke="white" stroke-width="2" stroke-miterlimit="10" />
                                        <path d="M16.7607 8.34766V17.3477H22.7607" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    <h5 className='text-white text-lg md:text-xl  xl:text-[25px] !leading-[1.5] font-semibold'>Revenue Growth</h5>
                                </div>
                                <p className='text-white text-xs leading-[1.6] rounded-full px-[6px] py-[2px] bg-[#52638D] font-normal'>Verified Results</p>
                            </div>
                            <p className='text-start text-white text-sm md:text-base lg:text-lg font-normal !leading-[1.5] mb-3 xl:mb-4'>Our sales reps help you achieve consistent growth while saving you valuable time and resources.</p>
                            <div className="p-4 rounded-2xl " style={{ backgroundImage: `linear-gradient(93deg, #2D395B 0%, #23418A 100%)` }}>
                                <h5 className='text-white text-xl lg:text-2xl xl:text-[31px]s !leading-[1.5] font-semibold mb-[2px]'>2.3x</h5>
                                <p className='text-white text-sm leading-[1.6] font-normal'>Average Revenue Growth</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 rounded-2xl md:rounded-[24px] lg:p-5 bg-[#2B65EA] mt-4 xl:mt-5">
                        <div className="grid grid-cols-2 gap-2 xl:gap-5">
                            {card.map((item, index) => (
                                <div className="p-5 px-2 rounded-[20px] border-[1.5px] border-[#547AE9] bg-[#4565E1]" key={index}>
                                    <div className="single flex flex-col justify-between h-full p-3 xl:p-4">
                                        <div>
                                            <div className="flex justify-center gap-[7px] mx-auto max-w-max">
                                                <div className="">
                                                    {item.icon}
                                                </div>
                                                <h5 className='text-start  text-base lg:text-lg xl:text-xl text-white font-medium !leading-[1.5] mb-1'>{item.title} </h5>
                                            </div>
                                            <p className='mb-2 text-xs !leading-[1.55] text-white'>{item.des}</p>
                                        </div>
                                        <div className={`line  flex-[0_0_auto] max-w-[160px] md:max-w-[220px] lg:max-w-[130px] xl:max-w-[220px] h-[8px] rounded-full  w-full !bg-[#5A84EC] relative z-[1]`}>
                                            <div className="absolute top-0 left-0 h-full rounded-full bg-[#93C5FD]" style={{ width: `${item.per}%` }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-between p-3 rounded-[64.871px] border border-[#547AE9] bg-[#4565E1] mt-5">
                            <p className='text-base !leading-[1.5] text-white flex items-center gap-1'> <span className='size-2 bg-[#4ADE80] inline-block rounded-full'></span>Optimized Performance</p>
                            <p className='text-base !leading-[1.5] text-white'>24/7 Performance Analytics</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
