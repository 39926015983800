export default function ServiceBox({ service, onInfoClick }) {
  // Add 'sales' to the nowrap list
  const shouldNoWrap = ['funding', 'design', 'development', 'automation', 'sales'].includes(service.id)
  const isDarkBg = service.color === "#74CC86" || service.color === "#595B5F"

  return (
    <div 
      className="rounded-2xl p-4 md:p-6 transition-all duration-300 hover:shadow-lg"
      style={{ backgroundColor: service.color }}
    >
      <div className="icon mb-3 md:mb-4 lg:mb-5">
        {service.icon}
      </div>
      
      <div className="space-y-3">
        <div className="flex items-start justify-between">
          <div>
            <h3 className={`font-medium text-lg md:text-xl ${
              isDarkBg ? 'text-white' : 'text-[#5A616C]'
            } ${shouldNoWrap ? 'whitespace-nowrap' : ''}`}>
              {service.title}
            </h3>
            {service.subtitle && (
              <h4 className={`font-medium text-lg md:text-xl mt-0.5 ${
                isDarkBg ? 'text-white/90' : 'text-[#5A616C]'
              }`}>
                {service.subtitle}
              </h4>
            )}
          </div>
          
          {service.hasModal && (
            <button
              onClick={onInfoClick}
              className={`ml-2 p-1 rounded-full hover:bg-white/10 transition-colors ${
                isDarkBg ? 'text-white' : 'text-[#5A616C]'
              }`}
            >
              <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
            </button>
          )}
        </div>

        <ul className="space-y-2">
          {service.points.map((point, index) => (
            <li 
              key={index}
              className={`flex items-center text-sm md:text-base ${
                isDarkBg ? 'text-white/90' : 'text-[#5A616C]'
              } ${point === 'Converting copywriting' || 
                 point === 'Email & SMS campaigns' || 
                 point === 'Full team management' || 
                 point === 'Commission structure' ? 'whitespace-nowrap' : ''}`}
            >
              <span className="mr-2">•</span>
              {point}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
} 