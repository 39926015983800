import { useState } from "react";
import { Container, Row, Col } from "./Grid";
import Card1 from "./Process/Card1";
import Card3 from "./Process/Card3";
import Card5 from "./Process/Card5";
import Card14 from "./Process/Card14";
import Card8 from "./Process/Card8";
import Card13 from "./Process/Card13";
import Card12 from "./Process/Card12";
import Card9 from "./Process/Card9";
import Card10 from "./Process/Card10";
import Card11 from "./Process/Card11";
import ProcessCard from "./ProcessCard";

export default function Process() {
    const items = [
        {
            title: "Strategic Growth Capital",
            des: "Smart business growth starts with exploring your funding options. Access $30K-$5M through our funding partners before investing your own capital.",
            info: <Card1 />,
        },
        {
            title: "Industry and Niche Selection",
            des: "We help identify the exact industry and niche that align with your strengths and aspirations, setting a solid foundation for your business journey.",
            info: false,
        },
        {
            title: "Crafting Your High-Ticket Offer",
            des: "Using a proven formula, we develop compelling high-ticket offers that make potential clients eager to engage with you.",
            info: <Card3 />,
        },
        {
            title: "React-Powered Websites",
            des: "We build top-tier, React-powered websites that help you stand out, ensuring you never lose another potential client due to an outdated or unprofessional site.",
            info: false
        },
        {
            title: "Custom Lead Generation Funnel",
            des: "We design and build custom lead generation funnels to collect and manage leads effectively, maximizing conversion opportunities.",
            info: <Card5 />
        },
        {
            title: "Strategic Media Buying",
            des: "We manage and optimize your advertising campaigns across multiple platforms, sparking curiosity and attracting high-quality prospects to your business.",
            info: false
        },
        {
            title: "Video Sales Letters",
            des: "We help you create compelling video sales letters that pre-sell your offers, making prospects excited to connect with your sales team.",
            info: false
        },
        {
            title: "Automated SMS & Email Follow-Up",
            des: "We build and manage custom SMS and email follow-up campaigns to stay in front of prospects, increasing the likelihood of conversion.",
            info: <Card8 />
        },
        {
            title: "Organic Outreach Strategies",
            des: "We handle all organic outreach, crafting personalized messages on platforms like LinkedIn, Instagram, and Facebook to book calls with qualified prospects.",
            info: <Card9 />
        },
        {
            title: "Dedicated Appointment Setters",
            des: "Our trained, U.S.-based appointment setters manage this process, filling your calendar with qualified prospects and high-quality opportunities.",
            info: <Card10 />
        },
        {
            title: "Expert Sales Reps",
            des: "We provide experienced sales reps to conduct sales calls on your behalf, ensuring sales are closed while you concentrate on core activities.",
            info: <Card11 />
        },
        {
            title: "E-Learning Course Creation",
            des: "We assist in creating and launching your digital courses, so you can offer valuable educational resources to clients and significantly increase your monthly revenue.",
            info: <Card12 />
        },
        {
            title: "One-on-One Support",
            des: "We pair you with one of our expert consultants for personalized guidance, providing you with three weekly 1-1 coaching calls to overcome challenges and scale throughout your business journey.",
            info: <Card13 />
        },
        {
            title: "Exclusive Access to Online Courses",
            des: "Access our top-selling courses covering offer structuring, systems creation, sales strategies, advertising, and content development to accelerate your success.",
            info: <Card14 />
        },
    ];

    return (
        <div className="process mt-12 md:mt-20 lg:mt-24 xl:mt-[150px]">
            <Container>
                {items.map((item, index) => (
                    <ProcessCard item={item} key={index} index={index} />
                ))}
            </Container>
        </div>
    );
}
