import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import Button from './Button'

const modalContent = {
  funding: {
    title: "Why Growth Capital?",
    description: "Traditional banks make you wait months. We partner with leading fintech providers to get you funded in days, not months. Scale your business without giving up equity."
  },
  design: {
    title: "Why Custom Design?",
    description: "Templates look like templates. Stand out with custom design that captures your unique value and converts visitors into clients. Your brand deserves better than drag-and-drop."
  },
  development: {
    title: "Why Next.js?",
    description: "Used by Nike and TikTok, Next.js delivers enterprise-level performance and analytics that basic HTML sites can't match. Don't settle for outdated tech."
  },
  automation: {
    title: "Why Automate?",
    description: "Stop losing leads to poor follow-up. Our GoHighLevel automation ensures no opportunity slips through the cracks, while our upcoming proprietary software will take your sales process to the next level."
  },
  sales: {
    title: "Why Our Sales Team?",
    description: "We attract top-tier closers because we deliver quality leads and proven offers. Your dedicated sales team is trained, managed, and motivated to scale your business while you focus on what matters."
  }
}

export default function ServiceModal({ isOpen, onClose, serviceId }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                {modalContent[serviceId] && (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-2xl md:text-3xl font-semibold text-gray-900 mb-5"
                    >
                      {modalContent[serviceId].title}
                    </Dialog.Title>
                    <p className="text-lg md:text-xl text-gray-700 leading-relaxed mb-8">
                      {modalContent[serviceId].description}
                    </p>
                    
                    <div className="mt-8 flex justify-end gap-4">
                      <button
                        type="button"
                        className="px-8 py-3 rounded-lg border border-gray-200 text-gray-700 hover:bg-gray-50 font-medium transition-colors text-lg"
                        onClick={onClose}
                      >
                        Close
                      </button>
                      <Button 
                        text="Schedule Now"
                        className="px-10 py-3 !rounded-lg !text-lg"
                      />
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
} 