import React, { useState } from 'react'
import { Row, Col } from '../components/Grid'

export default function ProcessCard({ item, index }) {

    const [activeIndex, setActiveIndex] = useState(false);
    const toggleCard = () => {
        setActiveIndex(!activeIndex);
    };
    return (
        <Row key={index} data-aos="fade-up" data-aos-offset="0" data-aos-delay={`${index + 0}00`} className={`relative z-[1] ${index === item.length - 1 ? "mb-0" : "mb-20 md:mb-28 lg:mb-36"}`}>
            <Col xs={12} md={4} lg={3}>
                <div>
                    <span className="font-general text-[#F2F5FA] font-semibold text-8xl md:text-[180px] xl:text-[220px] !leading-[0]">{index < 9 && "0"}{index + 1}</span>
                    <h3 className={`pr-10 md:pr-0 max-w-[200px] md:max-w-max lg:max-w-[260px] text-[#1F2A37] relative -top-8 md:-top-14 -mb-4 md:mb-0 bg-white font-general text-lg md:text-2xl lg:text-[28px] !leading-[130%] font-semibold tracking-[.07px] ${index === 10 ? "lg:!max-w-[200px]":""}`}>{item.title}</h3>
                </div>
            </Col>
            <Col xs={12} md={8} lg={9}>
                <div className="flex items-center justify-between gap-5 flex-wrap">
                    <span className="block md:w-[65%] text-sm md:text-base lg:text-xl">{item.des}</span>
                    {item.info && (
                        <button onClick={(e) => toggleCard(e)} className="absolute right-4 md:relative top-0 md:right-0 rounded-full p-0 bg-[#F2F6FA] flex items-center justify-center size-10 md:size-14 lg:size-16 border-0 text-primary hover:bg-primary hover:text-white" >
                            <svg className={`size-4 md:size-6 flex-[0_0_auto] transition-all ${activeIndex ? 'scale-y-100' : '-scale-y-100'}`} width="28" height="17" viewBox="0 0 28 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.33301 14.3337L13.9997 2.66699L25.6663 14.3337" stroke="currentColor" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    )}
                </div>
            </Col>
            <Col xs={12}>
                {item.info && activeIndex && (
                    <div className={`mt-8 md:mt-0 xl:mt-0 ${index === 7 ? '' : 'bg-[#F4F8FD] rounded-[40px] px-5 md:px-7 lg:px-10 py-7 md:py-10 lg:py-14 xl:py-16'}`}>
                        {item.info}
                    </div>
                )}
            </Col>
        </Row>

    )
}
