import React, { useEffect, useState } from 'react';
import { Container } from './Grid'
import { Link } from 'react-scroll';
import Logo from './Logo';
import Button from './Button';
import logo from '../assets/img/logo.png'

export default function Header() {
  const navLinks = [
    {
      btn_title: 'Our Process',
      to: 'process',
      offset:-200,
    },
    {
      btn_title: 'Testimonials',
      to: 'testimonials',
      offset:-80,
    },
    {
      btn_title: 'FAQ',
      to: 'faq',
      offset: -90,
    },
  ];
  const [isMenu, setIsMenu] = useState(false);
  const handleTouchStart = () => {
    setIsMenu(!isMenu);
  };

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isScroll = window.scrollY > 200;
      setScrolled(isScroll);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrolled, setScrolled]);

  return (
    <>
      <header className={`heading ${scrolled ? 'position-fixed' : ''}`}>
        <Container>
          <div className='heading-wrap flex items-center justify-between lg:bg-[#1F2937] rounded-full lg:p-2 lg:pl-4'>
            <Logo />
            <nav className={`heading-menu ${isMenu ? 'show-menu' : ''}`}>
              <div className="title flex items-center justify-between lg:hidden">
                <Logo />
                <div className="flex items-center gap-[6px]">
                  <a href="#" className='size-9 flex items-center justify-center bg-primary text-white rounded-full'>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.0934 12.1881C13.5972 11.6881 12.3953 10.9584 11.8122 10.6644C11.0528 10.2819 10.9903 10.2506 10.3934 10.6941C9.99532 10.99 9.73063 11.2544 9.26469 11.155C8.79875 11.0556 7.78625 10.4953 6.89969 9.61155C6.01313 8.7278 5.42032 7.68592 5.32063 7.22155C5.22094 6.75717 5.48969 6.49561 5.78281 6.09655C6.19594 5.53405 6.16469 5.4403 5.81156 4.68092C5.53625 4.0903 4.78532 2.89967 4.28344 2.40592C3.74657 1.87561 3.74657 1.96936 3.40063 2.11311C3.11898 2.23158 2.84879 2.37562 2.59344 2.54342C2.09344 2.87561 1.81594 3.15155 1.62188 3.56624C1.42782 3.98092 1.34063 4.95311 2.34282 6.77374C3.345 8.59436 4.04813 9.5253 5.50344 10.9766C6.95875 12.4278 8.07782 13.2081 9.71375 14.1256C11.7375 15.2591 12.5138 15.0381 12.9297 14.8444C13.3456 14.6506 13.6228 14.3756 13.9556 13.8756C14.1239 13.6207 14.2682 13.3508 14.3869 13.0694C14.5309 12.7247 14.6247 12.7247 14.0934 12.1881Z" stroke="white" strokeWidth="1.28796" strokeMiterlimit="10" />
                    </svg>
                  </a>
                  <button className="heading-toggler size-9" onClick={() => setIsMenu(!isMenu)}>
                    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.99978 6.59027L1.62812 10.9619C1.55034 11.0397 1.45478 11.0814 1.34145 11.0869C1.22812 11.0925 1.12701 11.0508 1.03812 10.9619C0.949229 10.8731 0.904785 10.7747 0.904785 10.6669C0.904785 10.5592 0.949229 10.4608 1.03812 10.3719L5.40978 6.00027L1.03812 1.62861C0.960341 1.55083 0.918674 1.45527 0.913118 1.34194C0.907563 1.22861 0.949229 1.1275 1.03812 1.03861C1.12701 0.949718 1.22534 0.905273 1.33312 0.905273C1.4409 0.905273 1.53923 0.949718 1.62812 1.03861L5.99978 5.41027L10.3715 1.03861C10.4492 0.960829 10.5451 0.919162 10.659 0.913607C10.7717 0.908051 10.8726 0.949718 10.9615 1.03861C11.0503 1.1275 11.0948 1.22583 11.0948 1.33361C11.0948 1.44138 11.0503 1.53972 10.9615 1.62861L6.58979 6.00027L10.9615 10.3719C11.0392 10.4497 11.0809 10.5456 11.0865 10.6594C11.092 10.7722 11.0503 10.8731 10.9615 10.9619C10.8726 11.0508 10.7742 11.0953 10.6665 11.0953C10.5587 11.0953 10.4603 11.0508 10.3715 10.9619L5.99978 6.59027Z" fill="black" />
                    </svg>
                  </button>
                </div>
              </div>
              <ul className="lg:flex items-center">
                {navLinks.map((link, index) => (
                  <li key={index} className='d-block'>
                    <Link className="heading-link capitalize" to={link.to} spy={true} smooth={true} duration={600} offset={link.offset} onClick={() => handleTouchStart(link.to)}>{link.btn_title}</Link>
                  </li>
                ))}
                <li className='lg:hidden mt-10 flex items-center justify-center'>
                  <a href="#" target='_blank' className='btn inline-flex items-center justify-between !gap-1 !pr-6'>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.0934 12.1881C13.5972 11.6881 12.3953 10.9584 11.8122 10.6644C11.0528 10.2819 10.9903 10.2506 10.3934 10.6941C9.99532 10.99 9.73063 11.2544 9.26469 11.155C8.79875 11.0556 7.78625 10.4953 6.89969 9.61155C6.01313 8.7278 5.42032 7.68592 5.32063 7.22155C5.22094 6.75717 5.48969 6.49561 5.78281 6.09655C6.19594 5.53405 6.16469 5.4403 5.81156 4.68092C5.53625 4.0903 4.78532 2.89967 4.28344 2.40592C3.74657 1.87561 3.74657 1.96936 3.40063 2.11311C3.11898 2.23158 2.84879 2.37562 2.59344 2.54342C2.09344 2.87561 1.81594 3.15155 1.62188 3.56624C1.42782 3.98092 1.34063 4.95311 2.34282 6.77374C3.345 8.59436 4.04813 9.5253 5.50344 10.9766C6.95875 12.4278 8.07782 13.2081 9.71375 14.1256C11.7375 15.2591 12.5138 15.0381 12.9297 14.8444C13.3456 14.6506 13.6228 14.3756 13.9556 13.8756C14.1239 13.6207 14.2682 13.3508 14.3869 13.0694C14.5309 12.7247 14.6247 12.7247 14.0934 12.1881Z" stroke="white" strokeWidth="1.28796" strokeMiterlimit="10" />
                    </svg>
                    Schedule Now
                  </a>
                </li>
              </ul>
            </nav>
            <div className="heading-actions flex items-center flex-wrap gap-2">
              <Button className="!hidden md:!inline-flex" text="Schedule Now" />
              <a href="#" className='size-9 flex lg:hidden items-center justify-center bg-primary text-white rounded-full'>
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.0934 12.1881C13.5972 11.6881 12.3953 10.9584 11.8122 10.6644C11.0528 10.2819 10.9903 10.2506 10.3934 10.6941C9.99532 10.99 9.73063 11.2544 9.26469 11.155C8.79875 11.0556 7.78625 10.4953 6.89969 9.61155C6.01313 8.7278 5.42032 7.68592 5.32063 7.22155C5.22094 6.75717 5.48969 6.49561 5.78281 6.09655C6.19594 5.53405 6.16469 5.4403 5.81156 4.68092C5.53625 4.0903 4.78532 2.89967 4.28344 2.40592C3.74657 1.87561 3.74657 1.96936 3.40063 2.11311C3.11898 2.23158 2.84879 2.37562 2.59344 2.54342C2.09344 2.87561 1.81594 3.15155 1.62188 3.56624C1.42782 3.98092 1.34063 4.95311 2.34282 6.77374C3.345 8.59436 4.04813 9.5253 5.50344 10.9766C6.95875 12.4278 8.07782 13.2081 9.71375 14.1256C11.7375 15.2591 12.5138 15.0381 12.9297 14.8444C13.3456 14.6506 13.6228 14.3756 13.9556 13.8756C14.1239 13.6207 14.2682 13.3508 14.3869 13.0694C14.5309 12.7247 14.6247 12.7247 14.0934 12.1881Z" stroke="white" strokeWidth="1.28796" strokeMiterlimit="10" />
                </svg>
              </a>
              <button className="heading-toggler lg:hidden size-9 !border !border-solid !border-[#E4E8F0] rounded-full text-[#5A616C] hover:bg-primary hover:text-white" onClick={() => setIsMenu(!isMenu)}>
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.3335 1H14.6668M1.3335 6H14.6668H3.8335M1.3335 11H9.66683" stroke="currentColor" strokeWidth="1.28796" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
          </div>
        </Container>
      </header>
    </>
  )
}
