import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

export default function Accordion({ items, activeItem }) {
  const [activeIndex, setActiveIndex] = useState(activeItem);
  const contentRefs = useRef([]);

  const toggleSlide = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div
          key={index}
          className={`accordion-item border border-[#DCE9FA] rounded-xl md:rounded-2xl ${activeIndex === index ? '!border-[#A3AFBF] bg-[#E2EDFB]' : ''
            }`}
        >
          <button
            onClick={() => toggleSlide(index)}
            className={`accordion-button flex text-start items-center justify-between w-full bg-transparent border-0 text-[#374151] ${activeIndex === index ? '!text-[#2563EB]' : 'collapsed'
              }`}
          >
            <span className="pr-16">{item.title}</span>
            {activeIndex !== index ? (
              <svg
                className="flex-[0_0_auto]"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.25L7 6.75L12.5 1.25"
                  stroke="currentColor"
                  strokeWidth="1.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                className="flex-[0_0_auto]"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 6.75L7 1.25L1.5 6.75"
                  stroke="currentColor"
                  strokeWidth="1.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            className={`${activeIndex === index ? 'p-[var(--padding)] !pt-0' : 'p-0'} accordion-body overflow-hidden transition-all duration-300 ease-in-out`}
            style={{
              maxHeight: activeIndex === index ? `${contentRefs.current[index]?.scrollHeight+20}px` : '0',
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: item.body }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
}

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.number,
};
